import { getSuggestedQuery } from "@testing-library/react";
import { JAVA, ONE_DAY, ONE_HOUR, ONE_SEC, getNumberFromLocalStorage, homeUrl, isBlankOrNullString } from "../utils/common_utils";
import { getJsonDataPostRequest } from "../utils/network_utils";
import { json } from "react-router-dom";

/**
 * fetches user details and server time every 12 hours or so
 *  for logged in users and also refreshes payment discount details from backend
 */
export function refreshHomePageDetails(){
    try{
        if(!isLoggedIn()) return
        let url = homeUrl()+"authApi/getHomePageDetails";
        let lastFetchTime=getNumberFromLocalStorage("lastFetchTime",0)
        // fetch every 6 hours
        if(Date.now()-lastFetchTime<=6*ONE_HOUR) return
        getJsonDataPostRequest(url, getAuth(), function(response){
            if(response==null||response.status>=400){
             return     
            }
            saveHomePageResponseData(response)
            localStorage.setItem("lastFetchTime",""+Date.now())  
        })
    }catch(exception){
        console.log("exception occured in refreshing home page details "+ exception)
    }
}

export function saveHomePageResponseData(data){
  // data.user, data.currentServerTime data.discount
  console.log(data)
  if(data.user && data.user!=null) saveUser(data.user)
  if(data.currentServerTime && data.currentServerTime>0){
    localStorage.setItem("currentServerTime",""+data.currentServerTime)
  }   
  saveDiscountForPremiumPlan(data.discount)
}

export function saveDiscountForPremiumPlan(discount){
  console.log("saving discount "+JSON.stringify(discount))
  if(isValidDiscount(discount)){
    localStorage.setItem("premium-page-discount", JSON.stringify(discount))
  } 
}

/** returns discount object, below is a sample object
 * multiline description should be split using double comma ,,
 PremiumPlanDiscount(
  oneLineDescription = "Exclusive First Week Offer, Get Premium at ",
  multiLineDescription = "First Week Offer ,, Get Premium at",
  discountPercentage = 80,
  showOnHomePage = true,
  thumbnailUrl = BASE_THUMBNAIL_URL+"discount-80-6.jpeg",
  finalAmount = 800,
  crossedAmount = 4000
)
*/
export function getPremiumPageDiscount(){
  try{
    if(isPremiumUser()) return {}
  var discount=localStorage.getItem("premium-page-discount")
 // console.log(discount)
  var parsedDiscount=JSON.parse(discount)
 // console.log(parsedDiscount)
  if(isValidDiscount(parsedDiscount)) return parsedDiscount;
}catch(exception){
  console.log("error occured in fetching premium page discount details "+exception)
}
return {}
}

/** checks discount, finalAmount, oneLineDescription and multiLineDescription */
export function isValidDiscount(discount){
  return discount!=null && discount && discount.finalAmount>0 &&
      discount.oneLineDescription && discount.oneLineDescription.length>0 &&
      discount.multiLineDescription && discount.multiLineDescription.length>0
}

/** returns -1 or last fetched server time
 * - server time can be upto 12 hours old
 */
export function getCurrentServerTime(){
  return getNumberFromLocalStorage("currentServerTime",-1)
}

export function isValidUser(user){
    if(user===null) return false
    return user && user.userId && user.userId>0 && 
            user.email && user.email.length>=5
}

/** saves user to localstorage */
export function saveUser(user){
    if(isValidUser(user))
        localStorage.setItem("user", JSON.stringify(user));
}

/** fetches user object from local storage */
export function getUser(){
    var user = localStorage.getItem("user")
    if(user==null || !user) return null
    let userObj= JSON.parse(user)
    // testing payments ui by changing parameters
   // userObj.creationDate=getCurrentServerTime()-4*ONE_DAY-1000
   
   // uncomment below line to show user is not a premium user
   // userObj.paymentValidTill=100  // 1744550103875
    return userObj
}

export function getAuth(){
    var user=getUser()
    if(!isValidUser(user)) return {}
    return {
        userId:user.userId, 
        secretKey: user.secretKey, 
        type:0
    }
}

export function isLoggedIn(){
   var auth = getAuth()
   return auth && auth.userId && auth.secretKey 
}

export function isPremiumUser(){
    try{
    if(!isLoggedIn()) return false;
    let user=getUser();
    let serverTime=getCurrentServerTime();
    if(!serverTime || serverTime==null || serverTime<=10)
     return false;
    if(serverTime<user.paymentValidTill) return true;
    }catch(exception){
        console.log("exception occurred "+exception);
    }
    return false;
}

/** 
 * This function will be used in case of premium content
* I need to update UI state when data from backend is fetched
* UI state is 0, 1, 2 and 3, 
* 0 : user not logged in, 
* 1 : user logged in but not a premium user and its their first week
* 2: user is not premium and has been there for more than a week
* 3: premium user
*/
export function getUIState(){
   try{
     if(!isLoggedIn()) return 0;
     let serverTime= getCurrentServerTime()
     let user=getUser()
     // if user is a premium user
     if(user.paymentValidTill>serverTime) return 3;
     // if user is not a premium user and 
     // account was created less than 1 week ago
     if(serverTime-user.creationDate<7*ONE_DAY) return 1;
     else return 2;
   }catch(exception){
     console.log("error in getting UI state "+exception)
   }
   return 0;
 }

 /** sets language like Java, Python */
 export function saveCurrentLanguage(language){
    if(isBlankOrNullString(language)) return
    localStorage.setItem("current_language", language);
}

/** fetches language like java, python from local storage */
export function getCurrrentLanguage(){
  const lang= localStorage.getItem("current_language")
  if(isBlankOrNullString(lang)) return JAVA
  return lang
}


