import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { homeUrl } from "../utils/common_utils";
import { getJsonDataPostRequest } from "../utils/network_utils";
import { getAuth } from "../login/auth_utils";
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";

function InstamojoPaymentRedirect() {
    // discountState=1
    const [error, setError] = useState('')
    const [showProgressBar, setShowProgressBar]=useState(true)
    const[paymentUrl, setPaymentUrl]=useState('')
    const auth = getAuth()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentId = searchParams.get('payment_id') || '';
    const paymentStatus = searchParams.get('payment_status') || '';
    const paymentOrderId = searchParams.get('id') || '';
    const transactionId = searchParams.get('transaction_id') || '';
    const navigate = useNavigate();

    useEffect(() => {
    let data={
        "auth":auth,
        "paymentId":paymentId,
        "transactionId":transactionId,
        "paymentStatus":paymentStatus,
        "paymentOrderId":paymentOrderId
    }
    let url = homeUrl()+"paymentsApi/paymentRedirect";
    getJsonDataPostRequest(url, data, function(response){
        setShowProgressBar(false);
        if(response==null||response.status>=400){
            setError(response.message || "Some error occured, please try again after a few minutes") 
            return    
        }
        // navigate('/premium'); // navigate was displaying cached page and i needed to refresh page after payments to show updated data
        window.location.href = '/premium';
    })

    }, []); // empty bracket means there are no dependencies which will make useEffect re run
     
     return (
       <div className="flex mt-20 items-center justify-center">
       
       {showProgressBar &&
       <div className="z-50 mt-2">
         <IndefiniteProgressBar />  </div> 
       }
       <br />
         <div className="  text-center text-xl font-medium">
            Payment in progress ... <br />
          Please do not close this page, <br />
          Do not press back button either

         </div>
       {error && error.length>0 &&
       <div className="text-lg font-semibold text-red-700">
       {error} <br /> 
       </div>
       }

       </div>
     );
   }

   export {InstamojoPaymentRedirect};