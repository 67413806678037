import { getUser } from "../login/auth_utils";
import { dateString } from "../utils/common_utils";

/**
 * creates UI with 75% discount 
 */
function PremiumUserUI({user, transactions}) {
    let rows=transactions.map((json, index) => (
      JSON.parse(json)
    ))
    return (
      <div className="flex justify-center">
        <div className="pt-8">
        <div className="text-xl text-orange-600 text-center">
            Thanks for being a Premium Member 
            <div className="text-lg mt-6 text-gray-600">
              {user.email}</div> 

           { user.paymentValidTill>1000 &&   <div className="text-lg font-medium mt-4 text-black">
             Payment valid till : &nbsp;
             <span className="text-green-600">{dateString(user.paymentValidTill)}
             </span>  </div>  
          }
        </div>
        
        <div className="mt-6 mb-6 flex items-center justify-center">
       
    {rows!=null && rows.length>0 &&
     <table className="table-auto border-collapse w-full m-4">

    <thead>
      <tr className="text-gray-800 text-center bg-white">
      <th className="px-2 py-3    text-gray-600 font-normal ">
          Transaction Id
        </th>
       
        <th className="px-2 py-3   text-gray-600 font-normal ">
          Payment Id
        </th> 
        <th className="px-2 py-3    text-gray-600 font-normal ">
          Amount
        </th>
        <th className="px-2 py-3  text-gray-600 font-normal ">
         Status
        </th> 
        <th className="px-2 py-3  text-gray-600 font-normal ">
          Date
        </th> 
      </tr>
    </thead> 
       
       
    <tbody className='p-20 font-normal'>  
    {rows.map((row) => (
    <tr key={row.transactionId} className="border-b border-gray-200 hover:text-blue-700
    even:white odd:bg-gray-100">
      
      <td className="px-4 pl-6 py-3">
        <span className="px-4 text-sm text-gray-500">
        {row.transactionId} </span>
      </td>

      <td className="px-4 pl-6 py-3">
        <span className="px-4 text-sm text-gray-500">
        {row.paymentId} </span>
      </td>

      <td className="px-4 pl-6 py-3">
        <span className="px-4 text-sm text-gray-500">
        {row.currency} &nbsp; {row.amount}/- </span>
      </td>

      <td className="px-4 pl-6 py-3">
        <span className="px-4 text-lg text-gray-600">
        { row.status === 1 ? 'Success' :
          row.status === 2 ? 'Fail' :
          'Pending'} </span>
      </td>

      <td className="px-4 pl-6 py-3">
        <span className="px-4 text-sm text-gray-500">
        {dateString(row.creationDate)} </span>
      </td>

    </tr>
    ))}
    </tbody>
        </table>
      }
        </div>
      
        </div>
      </div>
    );
  }

  export {PremiumUserUI};