import logo from './logo.svg';
import './App.css';
import {NavbarTop} from './ui_components/navbar_top';
import AllQuestionsTable from './questions/all_question_table';

function App() {
  return (
    <div>
      <NavbarTop />
      <div className="pt-20">
      <AllQuestionsTable />
      </div>
    </div>
  );
}

function DefaultPage(){
  const notFoundImg="https://public-items-1.s3.ap-south-1.amazonaws.com/codezym/not-found-404-2.jpeg"
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <NavbarTop />
      <div className='font-semibold text-4xl mt-40
       text-indigo-700' font-mono><center>
      <img
        className="object-cover rounded-lg 
         w-2/5"
        src={notFoundImg}
        alt={"404 image"}
        
      /> 
      <br />
      Page Not Found
      </center>
      
      </div>
    </div>
  );
}

function LoadingMessagePage() {
  return (
    <div>
      <NavbarTop />
      <div className='font-semibold text-3xl mt-40 text-indigo-700'><center><br /><br />
      Loading ...
      </center>
      
      </div>
    </div>
  );
}

function App1() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

//export default App;
export {App, LoadingMessagePage, DefaultPage};
//export {App, App1};
