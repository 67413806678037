import { getCurrentServerTime, getUIState, getUser } from "../login/auth_utils"
import { ONE_DAY, ONE_HOUR } from "../utils/common_utils"

/**
 * returns empty string if first week has already passed
 * else returns string like 'Only 6 days left' or 'Only 1 hour left' etc
 */
export function firstWeekDaysLeft(){
 try{
    let user=getUser()
    let diff=user.creationDate+ 7*ONE_DAY - getCurrentServerTime()
    if(diff>ONE_DAY){
        let num=parseInt(diff/ONE_DAY)
        if(num==1) return "Only 1 day left "
        return "Only "+num+" days left "
    }
    if(diff>ONE_HOUR){
        let num=parseInt(diff/ONE_HOUR)
        if(num==1) return "Only 1 hour left "
        return "Only "+num+" hours left "
    }
 }catch(exception){
    console.log("exception in firstWeekDaysLeft "+exception)
 }
 return ""
}