import React, { useState } from 'react';

/** e.g. title='Day 1', key ='roadmap1day1',
 *  childern= JSX component
 *  storageKey is used to store components open/close state
 *  in localStorage, it is a must and should be unique
*/
const ExpandCloseTab = ({ title, storageKey, children }) => {
  console.log(storageKey+" "+localStorage.getItem(storageKey))  
  const [isOpen, setIsOpen] = useState(
    (localStorage.getItem(storageKey)||"open")=="open");
  const handleClick = () => {
    let nw=!isOpen
    setIsOpen(nw);
    if(nw)localStorage.setItem(storageKey, "open")
    else localStorage.setItem(storageKey, "close")
  }
  return (
    <div className="mb-4 w-full mx-6 ">
      <button
        className={`w-full bg-gray-200 p-4  text-center md:text-left
        hover:bg-gray-300 hover:text-gray-900
         font-bold text-2xl rounded-t-lg ${
          isOpen ? 'text-gray-900' : ' w-full text-gray-600'
        }`}
        onClick={handleClick}
      >
        {title}
      </button>
      {isOpen && <div className="p-4 mx-12 md:mx-0  w-full rounded-b-lg bg-white shadow-md">{children}</div>}
    </div>
  );
};

/**
 * This is a responsive line break <br /> which doesn't show up on mobile
 * screens and only on tablet, desktop and above.
 * use this when you want to use full screen of mobile phone for reading text
 */
const BRR=()=>{
  return (
    <span class="hidden md:block"></span>
  )
}

export {ExpandCloseTab, BRR};