import { JAVA, success } from "../utils/common_utils";

/** Need to extract string from below fields in data
    - val executionStatus : Int ,
    - val executionMessage: String = "",
    - val errorList: List[String] = ArrayList(),
    - val logs: List[String] = ArrayList()
 */
export function testExecutionStatusToString(data, language){
    console.log(data);
    if(!data) return "<div class='italic text-blue-700'> still fetching ... </div>\n it can take 30 seconds to a few minutes"
    let msg = ""
    if(success(data.executionStatus)){
        msg = "<span class='font-bold text-green-700'> ALL TESTS PASSED </span>\n"
    }
    if(isFailedTestExecutionStatus(data.executionStatus==2) || data.executionStatus>=400){
        msg = "<span class='font-bold text-red-600'> TESTS FAILED  </span> \n"
    }


    msg = msg + (data.executionMessage || "")
    
    if(data.logs && data.logs.length>0){
        msg = msg+"\n <span class='font-bold text-blue-700'> ********* User Logs ******** </span>"
        for(let i=0;i< data.logs.length;i++){
          msg = msg+"\n"+data.logs[i]
        }
        msg = msg+"<span class=' text-blue-700'> ********* User Logs Ended ******** </span><br><br>"
       }

    // Replace multiple spaces with a single space
    msg = msg.replace(/ +/g, ' ');

    // Replace multiple newlines with a single newline
    msg = msg.replace(/\n+/g, '\n');
   // if(data.executionStatus==2 && language==JAVA)
   //   msg = updateJavaCompilationFailedLineNumber(msg)
  
    msg=msg.replace("<hr />",""); 
    msg=msg.replace("<hr>",""); 
   // console.log(msg);
    return msg.trim() ;
}

/**
 * 0 : execution not yet started, 1 : test is still running,
 * 4: compilation succeded and tests will start
 */
export function isTestRunning(status){
    return status==0 || status==1 || status==4;
}

/** status 2 : compilation failed , status>=400 is failure */
export function isFailedTestExecutionStatus(status){
    return status ==2 || status>=400;
}

/**
 * line number in compilation failed has to be line number -2,
 * because we add two lines i.e. \n containing package name at backend
 */
export function updateJavaCompilationFailedLineNumber(message){
    try{
        return message.replace(/Solution\.java:(\d+)/gi, function(match, number) {
          return "Solution.java:" + (parseInt(number) - 2);
        });
        //console.log(outputString);
              
    }catch(exception){}
    return message
}