import React, { useState } from 'react';
import {getUser, saveUser, isValidUser} from './auth_utils';
import { success, homeUrl, errorMsg, isValidEmail } from '../utils/common_utils';
import IndefiniteProgressBar from '../ui_components/indefinite_progress_bar';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [sendOTPLabel, setSendOTPLabel] = useState('Send OTP');
  const [loading, setLoading] = useState(false);
  
  
  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    e.preventDefault();
    setOtp(e.target.value);
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    // TODO: send email and OTP to server
    setError(false)
    if(!isValidEmail(email)){
      setError(true)
      setMessage("Please enter a valid email id")
      return
    }
    setMessage("Sending one time password ...")
    setSendOTPLabel("Send OTP Again")
    setLoading(true)
    setOtpSent(true) // immediately marked it true, so that user can enter otp if they already have it
            
    var data = {"email": email};
    fetch(homeUrl()+"authApi/sendLoginOtp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false)
          if(success(data.status)){
            var msg = data.message || "otp sent successfully"
            msg+="\n<br><br> <strong>NOTE</strong>: Please check your <strong>Spam</strong> folder as well for OTP email."
            setMessage(msg)
          }
          else{
            setError(true)
            setMessage(data.message || "Error in sending OTP, please try again")
          }
            console.log("response received")
            console.log(data);
        })
        .catch((error) => {
          console.log("error occured in sending otp "+JSON.stringify(error));
          setError(true)
          setLoading(false)
          setMessage(errorMsg())
        });
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    console.log(" handle otp submit "+otp);
    var data = {"email":email, "otp":otp};
    if(!isValidEmail(email) || !otp || otp.length<4){
      setError(true)
      setMessage("Please enter valid email or otp")
      return 
    }
    setError(false)
    setLoading(true)
    setMessage("Verifying OTP")
    fetch(homeUrl()+"authApi/verifyLoginOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false)
            if(success(data.status) && isValidUser(data.user)){
                saveUser(data.user)
                // refreshing page
                setMessage("user details fetched. Taking you to home page")
                window.location.reload()
            }
            else{
              setError(true)
              setMessage(data.message|| errorMsg())
            }
            console.log("response received after verifyLoginOTP")
            console.log(data.message+" "+data.status);
        })
        .catch((error) => {
          console.log("error occured in verifying otp "+JSON.stringify(error));
          setError(true)
          setLoading(false)
          setMessage(errorMsg())
        });
    // TODO: verify OTP with server and log user in if valid
  };

  return (
    <div className="bg-white h-3/4 flex items-center justify-center">
      <div className="bg-white p-2 rounded ">
        <h1 className="text-3xl font-semibold text-center mb-6">Login</h1>
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block text-gray-700 font-semibold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            placeholder = "abc@xyz.com"
            onChange={handleEmailChange}
            size="35"
            className="w-full text-gray-700 px-4 py-2 rounded outline outline-1 border-gray-400 focus:border-blue-500 "
          />
        </div>

        <div className="mb-6 flex items-center justify-center">
          <button
            onClick={handleSendOtp}
            className=" bg-gray-800 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600"
          >
            {sendOTPLabel}
          </button>
        </div>

        {otpSent && (
        <div className="mb-6">
          <label
            htmlFor="otp"
            className="block text-gray-700 font-semibold mb-2"
          >
            One Time Password
          </label>
          <input
            type="text"
            id="otp"
            value={otp}
            placeholder="123456"
            onChange={handleOtpChange}
            className="w-full text-gray-700 px-4 py-2 rounded outline outline-1 border-gray-400 focus:border-blue-500  "
          />
        </div>
        )}
        
       {otpSent && <div className="mb-6  flex items-center justify-center">
          <button
            onClick={handleVerifyOtp}
            className="bg-blue-500 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600"
          >
            Verify OTP
          </button>
        </div> }

        {message && message.length>5 && (
          <div className={`${error ? "bg-red-200 text-red-700 p-4" : "bg-blue-200 text-blue-700 p-4"} `} 
          dangerouslySetInnerHTML={{ __html: message }} >
            
          </div>
          )
        }
        {
          loading && (
            <div><br />
            <span className="ml-32 mt-32  z-50"><IndefiniteProgressBar />  </span> 
            </div>
          )
        }

         </div></div>
        ); 
    }

    export {LoginPage};
