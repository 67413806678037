import React, { useState } from 'react';

const FontSizeChanger = ({ fontSize, setFontSize }) => {
  setFontSize(parseInt(localStorage.getItem("codeEditorFontSize")) || 16)

  const handleFontSizeChange = (event) => {
    const newFontSize = fontSize + (event.target.value === '-' ? -1 : 1);
    if(newFontSize<8||newFontSize>100) return
    setFontSize(newFontSize);
    localStorage.setItem('codeEditorFontSize', newFontSize);
  };

  return (
    <span >
      <button
        className="text-xl text-white bg-green-700 hover:bg-green-500 font-bold py-1 px-3 rounded"
        onClick={handleFontSizeChange}
        value="-"
      >
        -
      </button>
      <span className="mx-1">{fontSize}</span>
      <button
        className="text-xl text-white bg-green-700 hover:bg-green-500 font-bold py-1 px-2 rounded"
        onClick={handleFontSizeChange}
        value="+"
      >
        +
      </button>
    </span>
  );
};

export default FontSizeChanger;