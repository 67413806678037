import React, { useState, useEffect, Children } from 'react';


function MockInterviewTopmateHomePage(){
    const url="https://topmate.io/prashant_priyadarshi/665067"
    
    return (
        <a className='flex flex-col m-4 px-8 py-4
         text-white bg-emerald-500 text-xl font-semibold
         text-center font-mono  rounded-md z-0 shadow-lg
         cursor-pointer leading-10
         hover:text-white hover:bg-emerald-700'
         
         href={url} target='_blank' >
         <span className='text-2xl font-bold'>Mock Interview</span>
         Low Level Design
         <button className='bg-white mt-4 text-gray-700
         rounded-full  hover:text-blue-700' 
         >
            Book</button>
        </a>
    )
}

export {MockInterviewTopmateHomePage}