import React, { useState, useEffect } from 'react';
import { ONE_DAY, ONE_HOUR, errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import { getAuth, getCurrentServerTime, getUIState, getUser, isLoggedIn, isValidUser, saveHomePageResponseData } from '../login/auth_utils';
import { getJsonDataPostRequest } from '../utils/network_utils';
import { BuyPremium} from './premium_discounts';
import { PremiumUserUI } from './premium_user_ui';
import LoginModal from '../login/email_login_modal';
import  { NavbarTop, NavbarWithOnlyHome } from '../ui_components/navbar_top';

function PremiumHome() {
  // state values decide which ui will be displayed   
  const [state, setState] = useState(-1);  
  const [transactionsList, setTransactionsList] = useState(-1);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('')
  const [showProgressBar, setShowProgressBar]=useState(true)
  console.log("going to load payment home page ")
  const[user, setUser]=useState(getUser())
  
  useEffect(() => {
    console.log("fetching the payments data");
    if(!isLoggedIn()) {
        setState(0);
        setShowProgressBar(false)
        return;
    }
    setShowProgressBar(true)
    if(!isLoggedIn()) setState(getUIState())
    else{
      let url = homeUrl()+"paymentsApi/fetchPaymentsHomePageData";
      getJsonDataPostRequest(url, getAuth(), function(response){
        if(response==null||response.status>=400){
           setError(response.message | "Some error occured, please try again")     
        }
        setTransactionsList(response.transactions)
        setUser(getUser())
        saveHomePageResponseData(response)
        setState(getUIState())
        setShowProgressBar(false);
    })
   }
     
}, []); // empty bracket means there are no dependencies which will make useEffect re run

  return (
  <div>
  { state!=3 && <NavbarWithOnlyHome />
  }
  { state==3 && <NavbarTop />
  }
    <div className='pt-20'></div>
    <div className = "flex flex-col w-screen items-center  justify-center mt-6">
      <p>
        <span className="text-6xl font-sans text-gray-800">
          Premium</span>
      </p>
      {
        state==0 && 
        <div className='items-center text-center mt-16'>
          <div className='text-lg font-medium text-gray-500'>
            Please Login to View Premium Plan</div>
          <br />
          <LoginModal />
        </div>
      }
      {
        (state==1 || state==2) && 
        <BuyPremium  />
      }

      {
        state==3 && <PremiumUserUI 
          user={user} transactions={transactionsList} />
      }
    
      {showProgressBar &&
      <div className=" mt-32  z-50">
        <IndefiniteProgressBar />  </div> 
      }

      {error && error.length>0 &&
      <div className="text-lg font-semibold text-red-700">
      {error} <br /> 
      </div>
      }

      {message && message.length>0 && !error && error.length==0 &&
      <div className="text-xl font-bold text-blue-700">
      {message} <br /> 
      </div>
      }

<div>
<br /> <br />
</div>
    
</div>
</div>
  );
        
}

export default PremiumHome;

