import { useState } from "react";
import {LoginPage} from "./email_login_tailwind"
import { getUser, isValidUser } from "./auth_utils";
import UserAccount from "./account_page";

function LoginModal() {
  const [showModal, setShowModal] = useState(false);
  //const [showAccount, setShowAccount] = useState(false);
  const user = getUser()
  var showAccount = isValidUser(user)
//  setShowAccount(isValidUser(user))

  return (
    <div>
      <button
        className="bg-orange-100  hover:bg-orange-300 hover:text-gray-900  text-orange-600 px-4 py-2  rounded-lg"
        onClick={() => setShowModal(true)}>
        {showAccount ? "Account" : "Login/SignUp"}
      </button>
   

      {showModal ? (
        <div className="fixed h-screen z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
            
              <div className="px-4 py-3 sm:p-6">
            { 
              !showAccount && <LoginPage />
            }

            {
             showAccount && <UserAccount />   
            }
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default LoginModal;