import { dateString } from "../utils/common_utils";
import { getUser } from "./auth_utils";

function UserAccount() {
    const user = getUser()
    const paymentValidTill = dateString(user.paymentValidTill)
    //console.log(user);
    const logout = (e) => {
      e.preventDefault();
      localStorage.clear()
      window.location.reload();
    };
  
    return (
      <div className="flex justify-center">
        <div className="pt-4">
        <div className="text-xl text-blue-700">
            Account Details <br />
            <span className="text-sm mt-4 text-black">
              {user.email}</span> <br/>
           { user.paymentValidTill>1000 &&   <span className="text-sm mt-4 text-black">
             Payment valid till :  {paymentValidTill}</span>  
          }
        </div>
        <div className="mb-6 flex items-center justify-center">
          <button
            onClick={logout}
            className=" bg-red-600 text-white px-4 py-2 mt-8 rounded font-semibold hover:bg-red-700"
          >
            Logout
          </button>
        </div>
        </div>
      </div>
    );
  }

  export default UserAccount;