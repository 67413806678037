import { useState } from "react";
/**
 * This is just UI for model with a close button.
 * Any other UI can be put inside it as children,
 * - title is text used in link button when modal is closed
 */
function GenericModal({ title, children }) {
  const [showModal, setShowModal] = useState(false);
  
  return (
  <span>
      <span
        className=" hover:text-blue-800 hover:font-semibold
         text-blue-600 py-2 underline hover:cursor-pointer "
        onClick={() => setShowModal(true)}>
        {title}
      </span>
   
      {showModal ? (
        <div className="fixed h-screen z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
            
              <div className="px-4 py-3 p-6 md:p-0">
            { 
              children
            }

              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </span>
  );
}

export {GenericModal};