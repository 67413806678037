import React, { useState } from 'react';
import { JAVA, PYTHON } from '../utils/common_utils';

const languages = [JAVA, PYTHON];

const SelectLanguageDropdown = ({ language,
             availableLanguages=languages, 
             onLanguageChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
    if (onLanguageChange) {
      onLanguageChange(event.target.value); // Call provided callback if available
    }
  };

  return (
    <div className="relative inline-block w-full ">
      <select
        value={selectedLanguage}
        onChange={handleChange}
        className="z-30 border ring-gray-800 text-gray-800 text-xl hover:bg-gray-200 cursor-pointer rounded py-2 px-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:bg-white"
        
      >
        {availableLanguages.map((lang) => (
          
          <option  className='text-xl' key={lang} value={lang}>
            {lang[0].toUpperCase() + lang.slice(1)}
          </option>
          
        ))}
      </select>
      <div className="z-40 absolute top-3 right-0 -mr-5 pointer-events-none">
      <svg height="40" width="40" viewBox="0 0 40 40" aria-hidden="true" focusable="false" className="css-8mmkcg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>

        </div>  
     { false && <div className="absolute top-0 right-0 -mt-2 mr-4 pointer-events-none">
        <svg
          className="h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 00-1.414 1.414L3 13.586V15a2 2 0 002 2h12a2 2 0 002-2v-1.414l-7.586-7.586A1 1 0 0010 5z"
            clipRule="evenodd"
          />
        </svg>
      </div>
     }
    </div>
  );
};

export {SelectLanguageDropdown};
