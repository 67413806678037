
/**
 * fetches data using post request
 * expects json output from backend
 */
export function getJsonDataPostRequest(url, data, callback) {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      callback(data);
    })
    .catch(error => {
        callback({
            status: 500
        })
      console.error('Error:', error);
    });
  }

  /**
   * used to fetch all the code as string using presigned get url. 
   * code for using below function
   * getTextFileAsString('https://example-bucket.s3.amazonaws.com/example-file.txt?AWSAccessKeyId=ACCESS_KEY_ID&Expires=EXPIRATION_TIME&Signature=SIGNATURE', function(text) {
  console.log(text);
  });
   */
  export function getTextFileAsString(url, callback) {
    if(!url || url.trim().length==0) {
      callback("")
      return
    }
    fetch(url, {
      method: 'GET'
    })
    .then(response => response.text())
    .then(text => {
      callback(text);
    })
    .catch(error => {
        callback("")
      console.error('Error:', error);
    });
  }
  
  /**
   * This function will be used for uploading code to s3
   */
  export function uploadTextToS3(text, presignedPutUrl, callback) {
    fetch(presignedPutUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: text
    })
    .then(response => {
      if (!response.ok) {
        callback({status :500, message: "error occured in saving text file. please try again"})
        console.log("error occured "+response)
        //throw new Error('Failed to upload text to S3');
      }
      else {
        callback({status:201});
        console.log('Code uploaded successfully');
      }
    })
    .catch(error => {
      callback({status :500, message: "error occured in saving text file. please try again"})
      console.log(error);
    });
  }

  /** adds js script dependencyfor the url */
  export function appendJSDependency(document, javaScriptFileUrl, callback){
    try{
    const script = document.createElement('script');
    script.src = javaScriptFileUrl;
    script.async = true; // Optional: Set async attribute for asynchronous loading
    document.head.appendChild(script);
    // Set up the onload event to call the callback function
    script.onload = () => {
    console.log(`${javaScriptFileUrl} has been loaded.`);
    if (callback) {
      callback();
     }
    }
  }catch(exception){
    console.log("error occured while adding js dependency \n "+javaScriptFileUrl+" \n "+exception)
  }
  }

  /** removes all js dependencies for the url even if they were added more than once */
  export function removeJSDependency(document, javaScriptFileUrl){
    try{
      // Find all script elements by the src attribute
      const scripts = document.querySelectorAll(`script[src="${javaScriptFileUrl}"]`);
      if(!scripts || scripts.length<=0)  return
      // Remove each script element found
      scripts.forEach(script => {
        document.head.removeChild(script);
      });
    }catch(exception){
      console.log("error occured while removing js dependency \n "+javaScriptFileUrl+" \n "+exception)
    }
  }
  

  

