import NavbarTop from "../ui_components/navbar_top";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState, useMemo } from 'react';
import Editor from '@monaco-editor/react';
import classNames from 'classnames';
import { JAVA, ALL_LANGUAGES, PYTHON, errorMsg, handleCtrlSave, homeUrl, success, isBlankOrNullString, getYoutubeVideoId, isYoutubeVideo } from "../utils/common_utils";
import { getAuth, getCurrrentLanguage, getUIState, isLoggedIn, isValidUser, saveCurrentLanguage, saveUser } from "../login/auth_utils";
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import { QuestionNotesLink } from "./question_notes";
import { YoutubeVideoDisplay } from "../ui_components/youtube_video_display";
import { LinkUI, LinkUIBasic, LinkUIBlueBold } from "../ui_components/common_links";
import { txb, txbb } from "../ui_components/text_style_components";

/**
 * This function fetches the list of all solutions for the given question . These can be code blogs, youtube videos etc
 */
function QuestionSolutions({question}) {
  const [loading, setLoading] = useState(false);
  const[error, setError] = useState('')
  const[message, setMessage] = useState('')
  const[solutions, setSolutions] = useState([])
  
 function fetchSolutions(questionId){
  console.log(" fetching solutions for question "+questionId)  
  setLoading(true)
  fetch(homeUrl()+"questionsApi/questionSolution/"+questionId, {
    method: "GET"
  })
    .then((response) => response.json())
    .then((data) => {
      setLoading(false)  
      console.log("fetched question solutions \n"+JSON.stringify(data))
      if(success(data.status) && data.list && data.list.length>0){
        var arr=[]
        for(let i=0;i<data.list.length;i++)
            arr.push(JSON.parse(data.list[i]))
        setSolutions(arr)
      }
      else{
        setError(data.message || "Error in fetching editorial, please try again")
      }
       // console.log("response received")
       // console.log(data);
    })
    .catch((error) => {
      console.log("error occured in fetching solutions "+JSON.stringify(error));
      setError(errorMsg())
      setLoading(false)
    });
 }
 
 function getPatternsString(patterns){
  if(!patterns||patterns.indexOf(",")<0) return patterns
  let str=""
  let words=patterns.split(",")
  for(let i=0;i<words.length-2;i++)str=str+words[i].trim()+", "
  let len=words.length
  return str+words[len-2].trim()+" and "+words[len-1].trim()
 }
 

// loading metadata and code 
useEffect(() => {
    console.log("showing solution page "+question.heading)
  if(question && question.questionId && question.questionId>0){  
    fetchSolutions(question.questionId)
  }
   
}, []); // empty bracket means there are no dependencies which will make useEffect run only once and not re run again and again

  return (
  <div className="mr-4 flex flex-col overflow-y-auto overflow-x-auto whitespace-no-wrap break-before-auto px-4">
    <div className="text-xl font-medium">
       {question.questionId}. &nbsp; {question.heading}
    </div>
    <div>
        
    <ul className="pt-2 text-sm">

    <li className="py-2 pr-6">- &nbsp; 
      {txb('If you are stuck') }
    with your solution code failing tests. <br />
    &nbsp; &nbsp;  Then don't get demotivated, 
    just share your code with us  <br />
    &nbsp; &nbsp;  at {txb(' contact@codezym.com')} and we will help you debug it.
     <br /> </li>

     <li className="py-2">- &nbsp; We regularly upload Low Level Design Interview Preparation Videos. <br />
     &nbsp; &nbsp; Do Subscribe to our 
     <LinkUIBlueBold 
     url = "https://www.youtube.com/@CodeZym/videos" 
     text="YouTube Channel"/>
     <br /> </li>

      <li className="py-2">- &nbsp; You <span className="text-red-600 font-bold">Don't </span>
       need to write any testing code or main method. <br /> </li>
      
      <li className="py-2">-  &nbsp; You <span className="text-red-600 font-bold">Only </span> 
      have to complete methods inside class Solution  <br />
      &nbsp; &nbsp; 
      in Java or Python (if available).
      <br />
      </li>
      <li className="py-2">- &nbsp;  Read <QuestionNotesLink /> 
      &nbsp; and watch  &nbsp;  
      <a className="text-blue-700 underline font-semibold"
      href="https://youtu.be/N5AIiVjGzHQ"
       target="_blank">Getting Started with CodeZym</a>
      </li>
      </ul><br />
    </div>

    { question && question.designPatterns 
        && question.designPatterns.length>0 && 
     <div className=" ">
       <span className="font-bold">Design Patterns used: &nbsp; </span> 
       {getPatternsString(question.designPatterns)} &nbsp; pattern
     </div>
    }
  { loading && <div className="m-4 text-center items-center">
       <span className="z-50"><IndefiniteProgressBar />  </span>  
    </div>
  }

   <div>
      { error &&
      <div className="text-red-700 
      text italic px-4 py-2">{error}</div>
      }
      {message &&
      <div className="text-indigo-700 text 
      px-4 py-2">{message}</div>
      } 
    </div>
    <br />   
   
    <div className="text-center font-medium text-xl">
       Below are a few solutions for this problem
      <br /> <br /> </div> 
       <hr className="h-px bg-gray-200 border-none" />

  { solutions && solutions.length>0 &&
    solutions.map((row, index) => (
      <div key={index}>
       
       { 
       <div className="text-center ">
       {
        row.text &&
        <span className=" font-normal " 
        dangerouslySetInnerHTML={{ __html: row.text }} />
       } 
       { row.url &&  !(isYoutubeVideo(row.url)) &&
       
         <span>  &nbsp;&nbsp; 
         <a href={row.url} target="_blank">
         <span className=" underline font-mono text-gray-800  text-xl"> 
           <LinkUIBasic url={row.url} text="Link" /> 
         </span> &nbsp;&nbsp; 
         </a>
        </span>   
       }
       </div>
       }
       
       {isYoutubeVideo(row.url) &&
        <div> 
        <YoutubeVideoDisplay videoId={getYoutubeVideoId(row.url)}
        />
        </div>
       } 
          
       <hr className="h-px my-4 bg-gray-200 border-none" />

      </div>  
    ))
  }
  <br /><br /><br /><br />  
  </div>  
    
  );
  
}

 export {QuestionSolutions};