
export const JAVA = "java"
export const PYTHON="python"
export const ALL_LANGUAGES=[JAVA, PYTHON]
export const ONE_SEC=1000
export const ONE_MIN=60*ONE_SEC
export const ONE_HOUR=60*ONE_MIN
export const ONE_DAY=24*ONE_HOUR

export function success(status){
    try{
    return status==200||status==201;
    }catch(err){}
    return false
}

/** setting up url for backend, url end with '/'  */
export function homeUrl(){
 // return "http://localhost/";  //local testing using frontend server
  return "/"; // production
}

/** Some error occured please try again */
export function errorMsg(){
    return "Some error occured please try again"
}

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

export function dateString(time){
    if(!time) time = Date.now();
    const date = new Date(time);
    const dateString = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
    const timeString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const formattedString = `${dateString}, ${timeString}`;
    //console.log(formattedString); // "24 Feb 2024, 5:20 pm"
    return formattedString;
} 

export async function handleCtrlSave(e, callbackSave) {
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && (e.keyCode === 83 || e.key==='s')) {
      e.preventDefault();
     // console.log('Saving...');
     await callbackSave();
    }
  }

  /** returns number value from local storage */
  export function getNumberFromLocalStorage(key, defaultValue=-1) {
    try {
      const value = localStorage.getItem(key);
      // Parse the value as a number and convert it to a long
      const longValue = parseInt(value, 10); // 10 is radix
      // Check if parsing resulted in a NaN (Not a Number)
      if (isNaN(longValue)) {
        return defaultValue;
      }
      return longValue;
    } catch (error) {
      console.error('Error retrieving value from localStorage for key: '+key+", error : "+ error);
      return -1;
    }
  }
  
  export function isBlankOrNullString(str){
    return str==null || !str || str=="" || str.length==0
  }

  export function getYoutubeVideoId(url) {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|\?feature=youtu.be\/))([^#&?]*)/i;
    const match = url.match(regExp);
  
    return match && match[1] ? match[1] : "";
  }

  export function isYoutubeVideo(url){
    if(!url) return false
    return url && (url.startsWith("https://www.youtube.com/") 
            || url.startsWith("https://youtu.be/"))
  }