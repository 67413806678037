import React, { useState, useEffect } from 'react';
import { errorMsg, homeUrl, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import {NavbarTop} from '../ui_components/navbar_top';

function QuestionNotes() {
  const [notesList, setNotesList] = useState([]);
  console.log("going to show all question notes table : ")

useEffect(() => {
  var NL = "<br> &nbsp; &nbsp; &nbsp; &nbsp; "
    var notes=[
        "You DON'T need to write any testing code or main method.",

       "You ONLY have to complete methods inside class Solution in Java or Python.",

       "As of now only Java till Java-11 and Python-3 are supported,"+NL+" other languages like C++, JavaScript are not supported.",

        "Use helper.print('') and helper.println('') for logging instead of "+NL+"  System.out.println() or println() else your logs won't be visible",

        "Any other class outside class Solution must be non public",

        "Put all import statements at the top, above class Solution"+NL+" and above all interface declaration, else it will give compiler error",

        "Class Solution constructor must always be public, "+NL+"  don't remove the public keyword",

        "If you are creating new interfaces, then they have to be declared on the top, "+NL+" even before class Solution, else it will give class not found error",

        "When init() method is called, then initialize and reset "+NL+" all of your instance variables and system state.",
    ];
    setNotesList(notes);  
}, []); // empty bracket means there are no dependencies which will make useEffect re run
// Talk is cheap, show me the code
  return (
  <div className = "flex justify-center">
        <NavbarTop />   <br /><br />

  {notesList.length>0 &&  <table className="table-auto border-collapse w-2/3 ">
      <thead>
        <tr className="text-gray-900 text-xl bg-white">
          <th className="px-4 mt-24 py-2 ">
            <br /><br /><br /><br />
         Notes 
         <br /><br />
            </th>
          
        </tr>
      </thead>
      <tbody className='p-20'>
        {notesList.map((row, index) => (
          <tr key={index+1} className="border-b border-gray-200 hover:text-blue-700 p-16 m-8">
            
            <td className="px-4 py-3">
              <span className="px-4">{index+1}.</span>
                <span className="text-black text-xl pb-16"
       dangerouslySetInnerHTML={{ __html: row }}>
        
        </span>
        <br />
            </td>
          </tr>
          
        ))}
      </tbody>
    </table>
  }

    </div>
  );
        
}

function QuestionNotesLink(){
    return (
    <span>
        <a href="../questionnotes/" target="_blank">
        <span className="font-semibold underline text-blue-700 ">  Important Notes
        </span>
            </a>
    </span>      
    );      
}

export  {QuestionNotes, QuestionNotesLink};

