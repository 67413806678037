import React, { useState, useEffect } from 'react';
import { errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import { LinkUI } from './common_links';

// https://img.youtube.com/vi/YMAwgRwjEOQ/mqdefault.jpg
// https://img.youtube.com/vi/YMAwgRwjEOQ/0.jpg 
// https://img.youtube.com/vi/YMAwgRwjEOQ/default.jpg
/** pass youtube videoId and title, as of now 
 * titleId is not getting used in ui, it will be used later
 */
function YoutubeVideoDisplay({ videoId, title='' }) {
    useEffect(() => {
    }, []) // empty bracket means there are no dependencies which will make useEffect re run
    const [isOpen, setIsOpen] = useState(false);
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  const youtubeVideoUrl=`https://www.youtube.com/watch?v=${videoId}`
 
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenYoutube = () => window.open(youtubeVideoUrl, '_blank');
  
  return (
    <div className=" m-2 flex flex-col items-center justify-center text-center">
      <img
        className="object-cover rounded-lg hover:opacity-50 cursor-pointer h-32"
        src={thumbnailUrl}
        alt={title || "YouTube Video Thumbnail"}
        onClick={handleOpenModal}
      />

      <div className='flex flex-row my-1 items-center justify-center text-center'>
      <button className='  mx-4 my-1
       text-white text-sm bg-red-500 h-8 w-12
      rounded-full hover:bg-red-600 cursor-pointer'
      onClick={handleOpenModal} >Play</button>

      <LinkUI url={youtubeVideoUrl} text="Link" />
      </div>
     

      {isOpen && <YoutubeVideoModal videoId={videoId} onClose={handleCloseModal} />}
    </div>
  );
}

const YoutubeVideoModal = ({ videoId, onClose }) => (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 overflow-y-auto h-full w-full z-50">
    <div className="relative p-4 w-full max-w-2xl mx-auto md:p-8">
      <button
        type="button"
        className="absolute top-3 right-2.5 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 z-10 "
        onClick={onClose}
      >
        Close
      </button>
      <iframe
        className="aspect-auto mt-4"
        width="100%"
        height="350px"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameborder="0"
        allowfullscreen
        allow="fullscreen;"

      />
    </div>
  </div>
);


export {YoutubeVideoDisplay, YoutubeVideoModal}