import React from 'react';
import ReactDOM from 'react-dom/client';
//import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import  {App, DefaultPage, LoadingMessagePage } from './App';
import QuestionDescription from './questions/question';
import AboutWebsite from './about';
import {QuestionNotes} from './questions/question_notes';
import PremiumHome from './premium/premium_home';
import { InstamojoPaymentRedirect } from './premium/instamojo_payment_redirect';
import { FirstPreparationPlan } from './preparation_plans/first_prep_plan';
// import reportWebVitals from './reportWebVitals';

//document.title = "Practice object oriented design questions in a multithreaded environment";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element = {<App />} />
        <Route exact path="/about" element = {<AboutWebsite />} />
        <Route exact path="/question/:questionId" element = {<QuestionDescription />} />
        <Route exact path="/questionnotes" element = {<QuestionNotes />} />
        <Route exact path="/premium" element = {<PremiumHome />} />
        <Route exact path="/roadmap" element = {<FirstPreparationPlan />} />
        <Route exact path="/payments/completed" 
        element = {<InstamojoPaymentRedirect />} />
        <Route path="*" element = {<DefaultPage />} />
      </Routes>
    </BrowserRouter>)
   // document.getElementById('root')
  
 {/* <React.StrictMode>
    <App />
  </React.StrictMode>
*/}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
